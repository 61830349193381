.brand-logo{
    font-family: 'Inconsolata', monospace;
}
.flow-text{
    font-family: 'Gayathri', sans-serif;
}
.card-title{
    font-family: 'Inconsolata', monospace;
}
.link{
    font-family: 'Gayathri', sans-serif;
}
.left{
    font-family: 'Gayathri', sans-serif;
}
body{
    background-color: #2d283e;
}
.title-grid{
    display: grid;
    grid-template-columns: 30% 60%;
    grid-gap:10%;
    height: 100px;

}
.card.horizontal{
    display:block;
}
.img-container {
    position: relative;
    width: 100%;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    -ms-transform: translate(10%, -50%);
    transform: translate(10%, -50%);
}